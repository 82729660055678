<template>
  <q-page>
    <div class="w100p bg-gris-panel q-px-lg q-py-sm row q-mb-md">
      <div class="col-8">
        <div class="fs-17 fs-w-600">Editar usuario</div>
        <q-breadcrumbs gutter="xs" class="mt-minus-2" active-color="grey-system">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <q-breadcrumbs-el :key="i" :label="breadcrumb.name" :to="breadcrumb.to" :class="{'text-template': (i +1) === breadcrumbs.length}"/>
          </template>
        </q-breadcrumbs>
      </div>
      <div class="col-4 text-right row justify-end items-center">
        <q-btn unelevated class="q-mr-sm e-border-1" color="gris-panel" text-color="grey-system" label="Salir" @click="enviarGestor()" ref="btnSalir"/>
        <q-btn-dropdown split class="glossy" color="primary" label="Guardar" @click="$refs.inputSubmitAgregar.click()">
            <q-list dense padding>
              <q-item clickable v-close-popup @click="$refs.inputSubmitAgregar.click()">
                <q-item-section>
                  <q-item-label>Guardar</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
        </q-btn-dropdown>
      </div>
    </div>

    <q-scroll-area class="h100-125">
      <div class="row justify-center w100p q-mt-md">
        <q-form @submit.prevent="editarUsuario()" class="content-container row justify-center" ref="formAgregar">
        <input type="submit" hidden ref="inputSubmitAgregar">
          <div class="header-content col-9">Datos generales de usuario</div>
            <div class="row justify-center col-6 q-mb-md">

              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Nombre completo</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <q-input 
                    class="col-6 bg-gris-panel" dense 
                    lazy-rules="ondemand" 
                    outlined 
                    disable
                    v-model.trim="usuarioObj.nombre_completo" 
                    maxlength="60"
                    ref="uno" tabindex="1"
                    :rules="[val => !!val || 'Nombre completo es requerido']">
                  </q-input>
              </div>
              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Correo electrónico</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                  <q-input
                    class="col-6"
                    dense
                    outlined 
                    lazy-rules="ondemand"
                    v-model.trim="usuarioObj.correo_electronico" 
                    maxlength="40"
                    :rules="[val => !!val || 'El correo es requerido']"
                    ref="dos" tabindex="2"
                  >
                  <template v-slot:append> <q-icon size="16px" name="las la-at" /> </template>
                  </q-input>
              </div>
              <!-- <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Teléfono</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                  <q-input 
                    dense class="col-4"
                    outlined
                    v-ingresar.numeros
                    lazy-rules="ondemand"
                    v-model.number="usuarioObj.telefono" 
                    maxlength="10"
                    :rules="[val => !!val || 'Teléfono es requerido']"
                    ref="cuatro" tabindex="4"
                    input-class="text-uppercase"
                  >
                    <template v-slot:append> <q-icon size="16px" name="phone" /> </template>
                  </q-input>
              </div> -->
              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Usuario</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <q-input 
                      dense class="col-6 bg-gris-panel"
                      lazy-rules="ondemand" 
                      outlined 
                      disable
                      v-model="usuarioObj.usuario"
                      ref="tres" tabindex="3"
                      :rules="[val => !!val || 'Usuario es requerido']" maxlength="30">
                    </q-input>
              </div>
              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Password actual</div>
                <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
                <q-input 
                  dense class="col-6 bg-gris-panel"
                  lazy-rules="ondemand" 
                  outlined
                  disable
                  type="password"
                  v-model="pass"
                  ref="cuatro" tabindex="4"
                  :rules="[val => !!val || 'Password es requerido']" maxlength="20">
                </q-input>
              </div>
              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Nuevo password</div>
                <div class="col-min text-red text-center q-pt-xs fs-17"></div>
                <q-input 
                  dense class="col-6"
                  lazy-rules="ondemand" 
                  outlined 
                  v-model="nuevoPass" 
                  ref="cinco" tabindex="5"
                  maxlength="20"
                  type="password"
                >
                  <template v-slot:append> 
                    <q-icon name="visibility_off" size="18px"></q-icon>
                  </template>
                </q-input>
              </div>
              <div class="row q-mb-md q-mt-xs col-12">
                <div class="col-3 text-right q-pt-sm fs-w-600">Confirmar nuevo password</div>
                <div class="col-min text-red text-center q-pt-xs fs-17"></div>
                <q-input 
                  dense class="col-6"
                  lazy-rules="ondemand" 
                  outlined 
                  v-model="nuevoPass2" 
                  ref="cinco" tabindex="5"
                  maxlength="20"
                  :type="isPwd ? 'password' : 'text'"
                  :rules="[
                    (val) => val == nuevoPass || 'No coincide su password',
                  ]"
                >
                  <template v-slot:append> 
                    <q-icon :name="isPwd ? 'visibility_off' : 'visibility'" class="cursor-pointer" size="18px" @click="isPwd = !isPwd"></q-icon>
                  </template>
                </q-input>
              </div>
            </div>
        </q-form>
      </diV>
    </q-scroll-area>
    <!--Seccion modales -->

  </q-page>
</template>

<script>
  import { isEmpty } from "lodash";
  import UsuarioService from "../../Services/UsuarioService";

  const usuarioService = new UsuarioService();

  export default {
    name:"usuarioEditar",
    data:() =>({
      breadcrumbs: [{name: "Home", to: "/home"}, {name: "Usuarios", to:"/usuarios"}, {name:"Editar usuario"}],
      usuarioObj: {},
      filtros: {},
      modalExito:false,
      usuarioId:"",
      isPwd: true,
      pass: 'XXXXXXXXXXXXXXXX',
      nuevoPass: '',
      nuevoPass2: ''
    }),
    watch:{
    },
    mounted () {
      this.cargaInicial();
    },
  methods:{
    isEmpty,
    async cargaInicial(){
      this.loader(true);
      this.$refs.dos.focus();
      await this.obtenerUsuario();
      this.loader(false);
    },
    async obtenerUsuario() {
      this.filtros.usuarioId = this.$route.params.id;
      await usuarioService.obtenerUsuario(this.filtros).then(resp => {
        this.usuarioObj = resp;
      }).catch(error => this.alertShow(error, "error"))
          .then(() => this.loader(false));
    },
    editarUsuario(detalle = true ){
      this.loader(true);

      let usuarioUpdate = {
        usuarioId: this.usuarioObj.usuario_id,
        correoElectronico: this.usuarioObj.correo_electronico,
        password: !isEmpty(this.nuevoPass) ? this.nuevoPass : null,
      };

      usuarioService.editarUsuario(usuarioUpdate).then(res => {
        let datos = res.datos;
        this.usuarioId = datos.usuarioId;

        if(detalle) this.$router.push({name:"usuariosGestor"});

        this.alertShow("Se actualizo usuario correctamente", "exito");
        this.limpiar();
      }).catch(error => {
          this.alertShow(error, "error")
      })
      .then(() => this.loader(false));
    },
    validacionEmail(valor) {
      if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(valor))
        return true;
      else
        return false;
    },
    limpiar(){
      this.agregar = {
        nombreCompleto:"",
        usuario:"",
        password:"",
        correoElectronico:"",
      };
    },
    enviarGestor(){
      this.$router.push({ name: "usuariosGestor"});
    },
    guardarEditar(){
      this.$refs.formAgregar.validate().then(exito => {
        if(!exito)
          return this.alertShow("Verifique que los datos sean correctos","warning");
        this.editarUsuario(false);
      });
    },
    focusGuardar(){
      this.$nextTick(() => {
        this.$refs.btnSalir.$el.focus();
      });
    }
  }
  }
</script>