import UsuarioRepo from "../Repositories/UsuarioRepo";

export default class UsuarioService{
  getUsuarios(filtros) {
    return UsuarioRepo.listar(filtros);
  }
  getUsuariosGestor(filtros) {
    return UsuarioRepo.listarGestor(filtros);
  }
  agregarUsuario(datos) {
    return UsuarioRepo.agregarUsuario(datos);
  }
  obtenerUsuario(datos){
    return UsuarioRepo.obtenerUsuario(datos);
  }
  editarUsuario(datos) {
    return UsuarioRepo.editarUsuario(datos);
  }
}